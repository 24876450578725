@import url('https://fonts.googleapis.com/css2?family=Gotu&family=Poppins&display=swap');

:root {
  /* Dark mode */
  --dark-background: #1a1a1a;
  --dark-navbar: #0D2818;
  --dark-menu: #16DB65;
  --dark-header: #058C42;
  --dark-active: #04471C;
  --dark-text: #fff;

  /* Light mode */
  --light-background: #FBFCFF;
  --light-navbar: #D0CCD0;
  --light-menu: #2c4a63;
  --light-active: #1C6E8C;
  --light-header: #165edb;
  --light-text: #000;
  }



html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  /* background: #000; */
  font-family: 'Poppins', sans-serif
}
.light-mode .link {
  color: var(--light-header);
}

.link {
  color: var(--dark-header);
}
a, li {
  text-decoration: none;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}
a:hover {
  font-weight: bold;
  transform: scale(1.1, 1.1);
}
ul{
  padding-inline-start: 20px;
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  
}
.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
  transition: all 2s;
}


.fade-out {
  overflow: hidden;
  animation: fadeOut 0.5s;
}

.fade-out.show {
  opacity: 1;
  max-height: fit-content;
  transition: all 0.5s ease-out;
}

.fade-out.hidden {
  opacity: 0;
  max-height: 0;
  transition: all 0.5s ease-in;
}


.unrotate {
  display: inline-block;
  transition: transform 0.5s ease;
  transform: rotate(0deg);
}
.rotate {
  display: inline-block;
  transition: transform 1s ease;
  transform: rotate(180deg);
}


@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media(prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}