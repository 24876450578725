

.loading-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    background: var(--dark-background);
    color: var(--dark-text);
    font-family: 'Gotu', sans-serif;
}
.loading-page #loading-name {
    font-size: 1.6em;
    color: var(--dark-header);
}

.loading-page #loading-title {
    font-size: 1.4em;
    color: var(--dark-header);
}

.light-mode .loading-page {
    background: var(--light-background);
    color: var(--light-text);
}

.light-mode .loading-page #loading-name {
    color: var(--light-header);
}

.light-mode .loading-page #loading-title {
    color: var(--light-header);
}