.light-mode #projects-page h2 {
  color: var(--light-header);
}
#projects-page h2 {
  color: var(--dark-header);
}
.github-icon {
  width: 50px;
  height: 50px !important;
  display: inline-block;
  margin-left: 10px !important;
}
.links-out {
  display: flex;
}

.card {
  position: relative;
  margin: 0px auto 20px;
  padding: 5px 20px;
  background-color: #fff;
  color: #000;
  max-width: 800px;
  border: var(--light-header) solid 1px;
  border-radius: 10px;
  overflow: hidden;
  transition: height 1s ease;
  -webkit-transition: height 1s ease;
  transition: 1.5s;
}
.card p {
  margin-block-start: 5px;
  margin-block-end: 5px;
}
.light-mode .visit-button {
  background-color: var(--light-header);
}
.visit-button {
  background-color: var(--dark-header);
  padding: 5px 10px;
  font-size: 1em;
  font-family: 'Poppins', sans-serif;
  border-radius: 5px;
  border: none;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
}
.project-skills {
  display: flex;
  flex-wrap: wrap;
    justify-content: center;
}

.card:hover {
  box-shadow: 0 4px 5px 3px rgba(252, 252, 252, 0.692) !important;
}
/*  .expand {
  height: 370px;
  max-height: 420px;
  max-width: 300px;
} */
.work.card.expand a img {
  height: 200px;
  max-width: 100%;
}

.project-skill {
  display: inline-block;
  margin: 0 5px;
  border: var(--dark-header) solid;
  border-radius: 5px;
  padding: 3px;
}
.light-mode .project-skill {
  border: var(--light-header) solid;
}

.expandContents {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 5px;
  width: 20px;
  cursor: pointer;
}

.projects-grid {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.card a {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  color: #000;
}
.card a img {
  height: 100px;
  margin: auto;
  border-radius: 10px;
}
.card .project-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.card .project-description.hideDescription {
  padding: 50px 0px;
}
.card a h3 {
  font-weight: bold;
  text-align: center;
  margin: 5px auto;
}
.card .project-description p {
  text-align: center;
}

.card .project-description .articleLink {
  color: var(--dark-menu);
  text-decoration: underline;
}

.light-mode .card .project-description .articleLink {
  color: var(--light-menu);
  text-decoration: underline;
}
@media only screen and (min-width: 768px) {
  .projects-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
  /*  .expand {
    max-width: 400px;
  } */
}
@media only screen and (max-width: 426px) {
  /*  .expand {
    height: fit-content;
    max-width: 300px;
  } */
  .work.card.expand a img {
    height: 150px;
    max-width: 100%;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(224, 18, 18, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(224, 18, 18, 0.4);
    box-shadow: 0 0 0 0 rgba(224, 18, 18, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
