#home {
  margin-bottom: 0px !important;
}

#profile-section {
  display: flex;
  flex-wrap: wrap;
}
#profileContainer {
  border-radius: 100%;
  width: 300px;
  height: 300px;
  /* background-color: #fff; */
  display: flex;
  margin: auto;
  position: relative;
}

#home #profileContainer #profilePic {
  width: 100px;
  margin: auto;
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: moveUpDown 2s infinite ease alternate;
}
@keyframes moveUpDown {
  to {
    transform: translateY(10px);
  }
}

#home div div:nth-child(1) {
  transition-delay: 200ms;
}
#home div div:nth-child(2) {
  transition-delay: 400ms;
}
#home div div:nth-child(3) {
  transition-delay: 600ms;
}
#home div div:nth-child(4) {
  transition-delay: 800ms;
}

@media only screen and (min-width: 768px) {
  #home #intro {
    width: 50%;
    margin-left: auto;
  }
}
