#contactForm {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: 1fr;
  max-width: 600px;
  margin: auto;

}
#contactForm .form-layout div {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}
#contactForm .form-control {
  padding: 8px;
  border-radius: 5px;
  background-color: #e6e6e6;
  font-family: 'Poppins', sans-serif
}

#contactForm div textarea {
  min-height: 150px;
}
#contactForm div input,
#contactForm div textarea {
  min-width: 200px;
  width: 100%;
  font-size: 1.25em;
}
#contactForm #submitBtn {
  max-width: 200px;
  width: 100%;
  margin: auto;
  font-weight: bold;
  font-size: 1.25em;
  margin: auto;
  width: 200px;
  height: 40px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
  background-color: var(--dark-active);
  color: var(--dark-text);
  border: solid var(--dark-active);
}
.light-mode #contactForm #submitBtn {
  background-color: var(--light-active);
  border: solid var(--light-active);
  color: var(--light-text);
}

#contactForm #submitBtn:hover {
  font-weight: bold;
  transform: scale(1.1, 1.1);
}
#thankYou p{
  margin-top: 30px;
  font-size: 1.25em;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  #contactForm label.mobileHide {
    display: inline;
    margin-right: 4px;
  }
  #contactForm div input,
  #contactForm div textarea {
    min-width: 200px;
    width: 80%;
    font-size: 1.25em;
  }
}
