.App {
  background: var(--dark-background);
}
.App .light-mode {
  background: var(--light-background);
}
#AppLayout {
  color: var(--dark-text);
  background: var(--dark-background);
  width: 90%;
  margin: 50px auto;
  padding: 10px 0;
  max-width: 1024px;
}
.light-mode #AppLayout {
  color: var(--light-text);
  background: var(--light-background);
}
#AppLayout section {
  margin: 40px auto;
  padding-bottom: 10px;
}

#AppLayout section h2 {
  cursor: pointer;
  color: var(--dark-header);
}

.light-mode  #AppLayout section h2 {
    cursor: pointer;
    color: var(--light-header);
  }

@media only screen and (min-width: 768px) {
  #AppLayout {
    width: 80%;
  }
}
