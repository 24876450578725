#navbar {
  /* display: flex;
  justify-content: center; */
  /* background-color: var(--dark-navbar);
  width: 100%; */
  /* position: relative; */
}
#desktop {
  display: none;
}

.DarkToggle {
  width: 50px;
  cursor: pointer;
}

#mobile {
  padding-bottom: 50px;
}
#mobile .navBar {
  position: fixed;
  background-color: var(--dark-navbar);
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 9999;
  font-family: 'Gotu', sans-serif;
}
#logo a {
  font-family: 'Gotu', sans-serif;
}
#mobile .navBar #logo a {
  color: var(--dark-menu);
  margin: auto;
  margin-left: 50px;
}


#mobile .navBar button {
  position: fixed;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 25px;
  font-weight: bold;
  color: var(--dark-menu);
  display: flex;
  justify-content: center;
  align-items: center;
}

#mobile #menuBtn {
  color: var(--dark-menu);
}

#mobile .menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background-color: var(--dark-navbar);
  left: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  max-width: 290px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#mobile .menuNav.showMenu {
  width: 80%;
  z-index: 9999;
  transition: width 1s ease;
}

#mobile a,
#mobile p {
  display: block;
  text-decoration: none;
  color: var(--dark-menu);
  /* text-transform: uppercase; */
  font-weight: bold;
  margin: 0px;
  font-size: 1em;
}

#mobile .menuNav li:first-child {
  margin-top: 7rem;
}

.light-mode #mobile .navBar {
  background-color: var(--light-navbar);
}

.light-mode #mobile .navBar #logo a {
  color: var(--light-menu);
}
.light-mode #mobile .navBar button {
  color: var(--light-menu);
}

.light-mode #mobile #menuBtn {
  color: var(--light-menu);
}

.light-mode #mobile .menuNav {
  background-color: var(--light-navbar);
}

.light-mode #mobile a,
.light-mode #mobile p {
  color: var(--light-menu);
}

@media only screen and (min-width: 768px) {
  #mobile {
    display: none;
  }
  .light-mode #desktop {
    background-color: var(--light-navbar);
  }
  .light-mode #desktop .navBar {
    background-color: var(--light-navbar);
  }
  #desktop {
    position: relative;
    top: 0;
    height: 50px;
    width: 100%;
    display: block;
    position: fixed;
    background-color: var(--dark-navbar);
    z-index: 9999;
    width: 100%;
    margin: auto;
  }
  #desktop .navBar{
    height: 50px;
    width: 100%;
    display: grid;
    align-content: center;
    grid-template-columns: 25% 75%;
    background-color: var(--dark-navbar);
    z-index: 9999;
    max-width: 1500px;
    width: 100%;
    margin: auto;
  }
  #logo {
    grid-column: 1;
    margin: auto;
  }
  #logo h2 {
    color: var(--dark-menu);
  }

  #links {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Gotu', sans-serif;
  }
  #links p {
    color: var(--dark-menu);
    text-decoration: none;
    margin: 0px;
    font-size: 20px;
    cursor: pointer;
  }
  #links a {
    color: var(--dark-menu);
  }

  .light-mode #desktop {
    background-color: var(--light-navbar);
  }
  .light-mode #logo h2 {
    color: var(--light-menu);
  }
  .light-mode #links a {
    color: var(--light-menu);
  }
}
