.headline {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 3em;
  color: #fff;
}

.inset {
  position: absolute;
  inset: 0;
}


.full {
  height: 50vh;
}
.full div:nth-child(3) {
    background-repeat: no-repeat !important;
    background-position: bottom right !important;
    background-size: 140%!important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient {
  background: linear-gradient(rgba(14, 62, 151, 0.5) 50%, black);
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .10em solid var(--dark-menu); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

.light-mode .typewriter {
  border-right: .10em solid var(--light-header); /* The typwriter cursor */

  animation: 
    typing 3.5s steps(40, end),
    blink-caret-light .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: var(--dark-menu); }
}
@keyframes blink-caret-light {
  from, to { border-color: transparent }
  50% { border-color: var(--light-header); }
}

@media only screen and (min-width: 426px) {
  .headline {
    font-size: 4em;
  }
  .full {
    height: 100vh;
  }
  .full div:nth-child(3) {
    background-repeat: no-repeat !important;
    background-position: bottom right !important;
    background-size: contain!important;
}
}
