#footer-layout {
  bottom: 0px;
  width: 100%;
  z-index: 2;
  background-color: var(--dark-navbar);
}

.light-mode #footer-layout {
  background-color: var(--light-navbar);
}
#footer {
  bottom: 0px;
  width: 100%;
  max-width: 1500px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  z-index: 2;
  background-color: var(--dark-navbar);
  color: var(--dark-menu);
  font-family: 'Gotu', sans-serif;
  margin: auto;
}
.light-mode #footer {
  background-color: var(--light-navbar);
  color: var(--light-menu);
}

#footer h3 {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#footer h3 .mobileHide {
  display: none;
}

#footer #footerLogos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
}
#footer #footerLogos a{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#footer #footerLogos img{
  width: 20px;
}

@media only screen and (min-width: 768px) {
  #footer h3 {
    font-size: 19px;
  }
  #footer h3 .mobileHide {
    display: inline;
    margin-right: 4px;
  }
  #footer #footerLogos {
    font-size: 25px;
  }
  #footer #footerLogos img{
    width: 35px;
  }
}
